<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="division"
    locale-section="pages.divisions"
    field-section="divisions"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "DivisionDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        parish
        hundred
        province
        municipality
        county
      `,
      inputAttributes: {
        parish: { required: true },
        hundred: { required: true }
      }
    };
  }
};
</script>
